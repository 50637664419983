import { Component, OnDestroy, OnInit } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { createSelector, Store } from "@ngrx/store";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { eventTypeInfoMap } from "src/app/global.constants";
import { ScheduleEventsActions } from "src/app/jobsv2/job-state/event-schedule-state/event-schedule.actions";
import { eventScheduleSelectors, selectShowDockTravelTimeCheckbox } from "src/app/jobsv2/job-state/event-schedule-state/event-schedule.selectors";
import { jobToolFeature } from "src/app/jobsv2/job-tool.reducer";
import { EstimateHelperService } from "src/app/services/estimate-helper.service";
import { FreyaHelperService } from "src/app/services/freya-helper.service";
import { ResponsiveHelperService } from "src/app/services/responsive-helper.service";
import { FreyaDatePipe } from "src/app/shared/freya-date.pipe";
import { SharedModule } from "src/app/shared/shared.module";
import { SubSink } from "subsink";

import { dayjs } from '../../../../core/dayjs/dayjs';
import { ScheduleV2Component } from "../../../../schedules/schedule-v2/schedule-v2.component";
import { scheduleFeature } from '../../../../schedules/store/schedules.reducer';
import { FullCalendarHelperService } from '../../../../services/full-calendar-helper.service';


@Component({
    selector: 'app-timeline-book-event',
    standalone: true,
    imports: [
        FreyaCommonModule,
        SharedModule,
        ReactiveFormsModule,
        ScheduleV2Component,
    ],
    templateUrl: './timeline-book-event.component.html',
    styleUrl: './timeline-book-event.component.scss'
})
export class TimelineBookEventComponent implements OnInit, OnDestroy {
    // @Input() timezone: string;
    // @Input() jobInDifferentTimezoneWarning: string;

    //observables
    job$ = this.store.select(jobToolFeature.selectJob);
    timezone$ = this.store.select(jobToolFeature.selectTimezone);
    jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
    eventToBook$ = this.store.select(jobToolFeature.selectEventToBook);
    eventBeingScheduled$ = this.store.select(jobToolFeature.isEventBeingScheduled);
    customerName$ = this.store.select(jobToolFeature.selectCustomerName);

    totalLocationTime$ = this.store.select(eventScheduleSelectors.selectTotalLocationTime);
    totalTravelTime$ = this.store.select(eventScheduleSelectors.selectTotalTravelTime);

    possibleTimes$ = this.store.select(jobToolFeature.selectAvailableTimes);
    hasLockedWindows$ = this.store.select(jobToolFeature.selectHasLockedWindows);
    
    possibleAssets$ = this.store.select(eventScheduleSelectors.selectPossibleAssets);

    selectedStartDate$ = this.store.select(createSelector(
        jobToolFeature.selectSelectedDate,
        (date) => {
            return dayjs(date).toDate();
        }
    ));
    selectedTime$ = this.store.select(jobToolFeature.selectSelectedStartTime);
    selectedTimeAsDate$ = this.store.select(createSelector(
        jobToolFeature.selectSelectedStartTime,
        (selectedStartTime) => {
            if (!selectedStartTime) { return undefined; }
            return new Date(selectedStartTime);
        }),
    );

    showDockTravelTimeCheckbox$ = this.store.select(selectShowDockTravelTimeCheckbox);
    includeDockTravel$ = this.store.select(jobToolFeature.selectIncludeDockTravel);
    selectedAssets$ = this.store.select(jobToolFeature.selectSelectedAssets);

    missingLocations$ = this.store.select( eventScheduleSelectors.selectEventMissingLocations );

    selectAssetTypes = createSelector(
        jobToolFeature.selectEventToBook,
        (eventToBook): string[] => (eventToBook && eventTypeInfoMap[eventToBook.type].assetTypes) || [],
    );

    assetTypeName$ = this.store.select(
        this.selectAssetTypes,
        (assetTypes) => {
            if (!assetTypes?.length) { return 'Assets'; }
            return assetTypes.join(', ');
        }
    );

    restrictionsDisabled$ = this.store.select(jobToolFeature.selectIsBookingRestrictionDisabled);

    selectFormDisabled = createSelector(
        jobToolFeature.selectJob,
        jobToolFeature.jobLoading,
        jobToolFeature.selectEventToBook,
        jobToolFeature.selectIsBookingRestrictionDisabled,
        eventScheduleSelectors.selectEventMissingLocations,
        (job, jobLoading, event, restrictionsDisabled, missingLocations,
        ) => {

            if (!job || jobLoading) { return true; }

            if (missingLocations?.length && !restrictionsDisabled) {
                return true;
            }

            return false;
        },
    );

    selectDateDisabled = createSelector(
        this.selectFormDisabled,
        jobToolFeature.selectCustomer,
        (formDisabled, customer) => {
            if (formDisabled) { return true; }
            if (!customer) { return true; }
            return false;
        },
    );
    dateDisabled$ = this.store.select(this.selectDateDisabled);

    selectTimeDisabled = createSelector(
        this.selectDateDisabled,
        jobToolFeature.selectAvailableTimes,
        jobToolFeature.selectSelectedDate,
        jobToolFeature.selectLoadingFindTime,
        (dateDisabled, availableTimes, selectedDate, loadingFindTime) => {
            if (dateDisabled) { return true; }
            if (!selectedDate) { return true; }
            if (!availableTimes?.length) { return true; }
            if (loadingFindTime) { return true; }
            return false;
        },
    );
    timeDisabled$ = this.store.select(this.selectTimeDisabled);
    dockTravelTimeDisabled$ = this.store.select(this.selectFormDisabled);

    assetsDisabled$ = this.store.select(createSelector(
        this.selectDateDisabled,
        eventScheduleSelectors.selectPossibleAssets,
        jobToolFeature.selectSelectedDate,
        jobToolFeature.selectLoadingFindTime,
        (dateDisabled, possibleAssets, selectedDate, loadingFindTime) => {
            if (dateDisabled) { return true; }
            if (!selectedDate) { return true; }
            if (!possibleAssets?.length) { return true; }
            if (loadingFindTime) { return true; }
            return false;
        },
    ));

    loadingFindTime$ = this.store.select(jobToolFeature.selectLoadingFindTime);

    bookButtonDisabled$ = this.store.select(createSelector(
        this.selectFormDisabled,
        jobToolFeature.isEventBeingScheduled,
        jobToolFeature.selectSelectedDate,
        jobToolFeature.selectSelectedStartTime,
        jobToolFeature.selectSelectedAssets,
        jobToolFeature.selectLoadingFindTime,
        (
            formDisabled, eventBeingScheduled, selectedDate,
            selectedTime, selectedAssets, loadingFindTime
        ) => {

            if (formDisabled) { return true; }
            if (eventBeingScheduled) { return true; }
            if (!selectedDate) { return true; }
            if (!selectedTime) { return true; }
            if (!selectedAssets?.length) { return true; }
            if (loadingFindTime) { return true; }

            return false;
        }
    ));

    showTimeNotAvailableWarning$ = this.store.select(jobToolFeature.selectShowTimeNotAvailableWarning);
    showAssetsNotAvailableWarning$ = this.store.select(jobToolFeature.selectShowAssetsNotAvailableWarning);
    showMissingLocationsWarning$ = this.store.select(createSelector(
        eventScheduleSelectors.selectEventMissingLocations,
        jobToolFeature.selectIsBookingRestrictionDisabled,
        (missingLocations, restrictionsDisabled) => {
            return missingLocations?.length && !restrictionsDisabled;
        }
    ));

    showJobInDifferentTimezoneWarning$ = this.store.select(createSelector(
        jobToolFeature.selectEventToBook,
        () => {
            // TODO: this logic
            return false;
        })
    );

    showYemboEstimatorNoEmailWarning$ = this.store.select(createSelector(
        jobToolFeature.selectEventToBook,
        jobToolFeature.selectSelectedAssets,
        () => {
            // TODO: replace this logic from the book logic
            return false;
        })
    );


    scheduleMode$ = this.store.select(jobToolFeature.selectScheduleMode);
    alreadyScheduled$ = this.store.select(jobToolFeature.selectAlreadyScheduled);

    timePlaceholder: string = 'Select Time';

    // COMPONENT STATE VARIABLES
    // eventForm = new FormGroup({
    //     date: new FormControl<string>({ value: undefined, disabled: true }, [Validators.required]),
    //     time: new FormControl<number>({ value: undefined, disabled: true }, [Validators.required]),
    //     assets: new FormControl<Asset[]>({ value: [], disabled: true }, [Validators.required]),
    //     includeDockTravelTime: new FormControl({ value: true, disabled: true }),
    // });

    // // Used to reset
    // eventFormValues = cloneDeep(this.eventForm.value);

    // BOOKING VARIABLES
    // A list of the required locations that are missing for the event type
    // missingLocations: string[] = [];

    formattedLockDate = {
        long: undefined,
        short: undefined,
    };

    rescheduleEventMode = false;

    ScheduleEventsActions = ScheduleEventsActions;

    private subs = new SubSink();

    constructor(
        public store: Store,
        public estimateHelper: EstimateHelperService,
        public freyaHelperService: FreyaHelperService,
        private fcHelper: FullCalendarHelperService,
        public responsiveHelper: ResponsiveHelperService,
        private freyaDatePipe: FreyaDatePipe,
    ) { }

    ngOnInit(): void {
        this.subs.sink = this.freyaHelperService.lockDate$.subscribe((unixLockDate) => {
            this.setIsFormattedLockDate();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    close() {
        this.rescheduleEventMode = false;
        this.store.dispatch(ScheduleEventsActions.bookDialogClosed());

    }

    rescheduleButtonClicked() {
        // const dateFromForm = this.eventForm.value.date;
        // const dateFromEvent = timestampToDateString(this.event?.event?.start, this.timezone);

        this.store.dispatch(ScheduleEventsActions.rescheduleButtonClicked(
            // date: dateFromForm || dateFromEvent,
            // eventType: this.event.event.type,
            // currentEventIdToExclude:  this.event.event.id
        ));

        this.rescheduleEventMode = true;

    }

    updateEventButtonClicked() {
        this.store.dispatch(ScheduleEventsActions.updateEventButtonClicked());
        this.rescheduleEventMode = false;
    }

    setIsFormattedLockDate() {
        this.formattedLockDate = {
            long: this.freyaHelperService.getFormattedLockDate(),
            short: this.freyaHelperService.getFormattedLockDate('MMM DD'),
        };
    }

    // eventDateSelected() {
        // const date = this.eventForm.value.date;
        // Create date object from string
        // const dateObj = new Date(date);
        // this.fcHelper.changeCalendarDate.next(dateObj);
    // }

    // eventTimeSelected() {
    //     const rawTime = this.eventForm.controls.time.value;
    //     //TO DO need to move it from component, either to reducer or to effect
    //     //to do convertation in reducer, need to have timezone in jobFeature
    //     const time = getStartUnix(rawTime, this.timezone);
    //     this.store.dispatch(
    //         ScheduleEventsActions.timeSelected({
    //             time,
    //             eventId: this.event.event.id,
    //         })
    //     );
    // }

    // assetsSelected() {
    //     const selectedAssetsIds = this.eventForm.controls.assets.value.map((a) => a.id);

    //     const possibleAssetsForPlaceholder = this.possibleAssets?.filter(
    //         a => a?.id && selectedAssetsIds.includes(a.id)
    //       );

    //     this.store.dispatch(ScheduleEventsActions.assetsSelected({
    //         selectedAssetsIds,
    //         eventType: this.event?.event?.type,
    //         placeholderEventInfo: {
    //             id: this.event?.event?.id,
    //             type: this.event?.event?.type,
    //             title: this.event?.event?.title,
    //             start: this.selectedTime,
    //             //end is calculated in reducer if not passed from UI
    //             assets: possibleAssetsForPlaceholder,
    //         }}));
    // }

    bookEventButtonClicked() {
        this.store.dispatch(ScheduleEventsActions.bookButtonClicked());
    }

}