import { createActionGroup, createFeature, createReducer, createSelector, on, props } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { BaseConfigValueFragment, FullPermissionFragment, FullProductFragment, ZoneBranding } from '../../generated/graphql.generated';
import { defaultCountry, jurisdictions } from '../global.constants';
import { filterOutProductsWithInactivePrices, sortProductsByCategory } from '../jobsv2/jobsv2-charges-helpers';
import { ConfigKey, ConfigValue, formatConfigValue } from '../services/frontend-configs';
import { WatchPermissionsAndRestrictionsInput } from '../services/permission.service';
import { permissionHasRestrictions, resolvePermissionFromArray } from '../services/permissions.util';

const checkPermissions = (allPermissions: FullPermissionFragment[], permissionsAndRestrictions: WatchPermissionsAndRestrictionsInput[]) => {

	const mappedResults = permissionsAndRestrictions.map((input) => {
		const permission = resolvePermissionFromArray(allPermissions, input.permission);
		if (!permission) { return undefined; }
		if (!input.restriction) { return permission; }

		if (!permissionHasRestrictions(permission, input.restriction)) {
			return undefined;
		}

		return permission;
	});

	return mappedResults.map(Boolean);
};


export const BrandingActions = createActionGroup({
	source: 'Branding',
	events: {
		'Branding Loading': props<{
			loading: boolean;
		}>(),
		'Branding Load Error': props<{
			error: Error;
		}>(),
		'Branding Updated': props<{
			branding: ZoneBranding,
			features: string[],
			configValues: BaseConfigValueFragment[],
			permissions: FullPermissionFragment[]
			products: FullProductFragment[]
		}>(),
	},
});

const brandingInitialState = {
	branding: undefined as ZoneBranding,
	configs: {} as { [key in ConfigKey]: ConfigValue<key> },
	country: defaultCountry,
	permissions: [] as FullPermissionFragment[],

	currency: defaultCountry.currency,
	units: defaultCountry.units,

	loading: true as boolean,
	products: [] as FullProductFragment[],
};

export type BrandingState = typeof brandingInitialState;

export const brandingFeature = createFeature({
	name: 'Branding',
	reducer: createReducer(
		brandingInitialState,
		on(BrandingActions.brandingUpdated, (state, { branding, configValues, permissions, products }): BrandingState => {

			const configs = {} as typeof state.configs;

			// configValues may be null if we are not authenticated
			for (const config of configValues || []) {
				configs[config.key] = formatConfigValue(config);
			}

			const countryCode = branding.country || environment.defaultCountry;

			let country = jurisdictions.find((j) => j.country === countryCode);
			if (!country) {
				country = jurisdictions.find((j) => j.country === environment.defaultCountry);
			}

			const currency = country.currency;
			const units = country.units;

			const productsWithActivePrices = filterOutProductsWithInactivePrices(products);
			const productsSortedByCategory = sortProductsByCategory(productsWithActivePrices);

			return {
				...state,
				branding,
				configs,
				currency,
				permissions,
				units,
				products: productsSortedByCategory,
				loading: false,
			};
		}),
		on(BrandingActions.brandingLoading, (state, { loading }): BrandingState => {
			return {
				...state,
				loading,
			}
		}),
	),
});

export function createConfigSelector<T extends ConfigKey, R = any>(configKey: T) {
	return createSelector(brandingFeature.selectConfigs, (configs) => {
		return configs[configKey];
	});
}

export function createCheckMuliplePermissionsSelector(permissionsAndRestrictions: WatchPermissionsAndRestrictionsInput[]) {
	// Create a new selector that will return a boolean array
	return createSelector(brandingFeature.selectPermissions,
		(allPermissions) => checkPermissions(allPermissions, permissionsAndRestrictions));
}

export function createCheckSinglePermissionSelector(permissionAndRestriction: WatchPermissionsAndRestrictionsInput) {
	return createSelector(brandingFeature.selectPermissions, (allPermissions) => {
		const [result] = checkPermissions(allPermissions, [permissionAndRestriction]);
		return result;
	});
}

export const selectSystemDetails = createSelector(brandingFeature.selectBranding, (branding) => {
	if (!branding) { return; }
	return {
		timezone: branding.timezone,
		country: branding.country,
		language: branding.language,
	}
});
