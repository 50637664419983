<section class="header">
  <ng-container *ngIf="jobLoaded$ | async; else loading">
    <app-overview-chip
      icon="pi-calendar"
      value="{{ totalItems$ | async }}"
      label="Items"
    ></app-overview-chip>
    <app-overview-chip
      icon="pi-calendar"
      [value]="'0'"
      label="Boxes"
    ></app-overview-chip>
    <app-overview-chip
      icon="pi-calendar"
      value="{{ totalWeight$ | async }} {{ weightUnit$ | async }}"
      label="Weight"
    ></app-overview-chip>
    <app-overview-chip
      icon="pi-calendar"
      value="{{ totalVolume$ | async }} {{ volumeUnit$ | async }}"
      label="Volume"
    ></app-overview-chip>
  </ng-container>
</section>

<section class="inventory-list">
  <div
    *ngIf="inventoryNotes$ | async"
    class="inventory-notes"
  >
    <h2>Legacy Inventory Notes:</h2>
    <span>
      {{ inventoryNotes$ | async }}
    </span>
  </div>

  <div class="inventory-header">
    <h2>Inventory</h2>
    <div class="button-list">
      <p-button
        label="Add Room"
        icon="pi pi-plus"
        [outlined]="true"
        (click)="store.dispatch(InventoryActions.addRoomButtonClicked())"
      ></p-button>

      <ng-container *ngIf="yemboHelper.yemboEnabled; else bookSmartConsultButton">
        <p-button
          *ngIf="isYemboMoveCreated$ | async; else bookSmartConsultSplitButton"
          icon="pi pi-external-link"
          label="View Move in Yembo"
          (onClick)="viewMoveInYembo()"
        />
      </ng-container>

      <!-- <p-button
        icon="pi pi-save"
        [disabled]="(hasChanges$ | async).length === 0"
        (click)="store.dispatch(InventoryActions.saveButtonClicked())"
      ></p-button> -->
    </div>
  </div>

  <table class="inventory">
    <thead>
      <!-- <th></th> -->
      <th class="inventory-name">Item</th>
      <th class="inventory-value hidden-sm">Quantity</th>
      <!-- <th class="inventory-value">Boxes</th> -->
      <th class="inventory-value hidden-sm">Weight</th>
      <th class="inventory-value hidden-sm">Volume</th>
      <th class="inventory-value show-sm">Quantity<br />Volume<br />Weight</th>
      <th><!-- Actions --></th>
    </thead>
    <tbody>
      <ng-container *ngFor="let room of rooms$ | async; trackBy: trackByRoomId">
        <tr class="room">
          <!-- <td class="inventory-media"></td> -->
          <td>{{ room.name }}</td>
          <td class="inventory-value hidden-sm">{{ room.totalItems || 0 }}</td>
          <!-- <td class="inventory-value">{{ room.totalBoxes || 0 }}</td> -->
          <td class="inventory-value hidden-sm">
            {{ room.totalWeight || 0 }}{{ weightUnit$ | async }}
          </td>
          <td class="inventory-value hidden-sm">
            {{ room.totalVolume || 0 }}{{ volumeUnit$ | async }}
          </td>
          <td class="inventory-value show-sm">
            {{ room.totalItems || 0 }}<br />
            {{ room.totalWeight }}{{ weightUnit$ | async }}<br />
            {{ room.totalVolume }}{{ volumeUnit$ | async }}
          </td>
          <td class="inventory-actions">
            <div>
              <p-button
                icon="pi pi-times"
                [rounded]="true"
                [text]="true"
                (click)="store.dispatch(InventoryActions.removeRoomClick({
                  roomId: room.id,
                }))"
              />
            </div>
          </td>
        </tr>

        <tr
          class="item"
          *ngFor="let item of room.inventory; trackBy: trackByItemId"
        >
          <!-- <td class="inventory-media"></td> -->
          <td class="inventory-name">{{ item.name }}</td>
          <td class="inventory-value hidden-sm">
            <p-inputNumber
              [ngModel]="item.quantity"
              [min]="0"
              [showButtons]="true"
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              id="quantity-{{ item.id }}"
              #quantityInput
              [step]="1"
              class="dynamic-width-input"
              (onInput)="store.dispatch(InventoryActions.quantityUpdated({
                item,
                quantity: $event.value,
                roomId: room.id,
                doFocus: 'quantity-' + item.id,
              }))"
              [autofocus]="(focus$ | async) === 'quantity-' + item.id"
              decrementButtonClass="p-button-secondary p-button-outlined"
              incrementButtonClass="p-button-secondary p-button-outlined"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
          </td>
          <!-- <td class="inventory-value"></td> -->
          <td
            class="inventory-value hidden-sm"
            pTooltip="{{ item.weight }} {{ weightUnit$ | async }}/unit"
          >
            {{ item.totalWeight }}{{ weightUnit$ | async }}
          </td>
          <td
            class="inventory-value hidden-sm"
            pTooltip="{{ item.volume }} {{ volumeUnit$ | async }}/unit"
          >
            {{ item.totalVolume }}{{ volumeUnit$ | async }}
          </td>
          <td class="inventory-value show-sm">
            {{ item.quantity }}<br />
            {{ item.totalWeight }}{{ weightUnit$ | async }}<br />
            {{ item.totalVolume }}{{ volumeUnit$ | async }}
          </td>
          <td class="inventory-actions">
            <div>
              <p-button
                icon="pi pi-pencil"
                [rounded]="true"
                [text]="true"
                (click)="store.dispatch(InventoryActions.editItemClick({
                    item,
                    roomId: room.id,
                }))"
              />
              <p-button
                icon="pi pi-times"
                [rounded]="true"
                [text]="true"
                (click)="store.dispatch(InventoryActions.removeItemClick({
                    item,
                    roomId: room.id,
                }))"
              />
            </div>
          </td>
        </tr>
        <tr>
          <!-- <td>MEDIA</td> -->
          <td class="inventory-item-name add-item">
            <p-autoComplete
              #itemSearch
              id="item-search-{{ room.id }}"
              [autofocus]="(focus$ | async) === 'item-search-' + room.id"
              placeholder="Add Item..."
              [suggestions]="searchResults$ | async"
              (completeMethod)="store.dispatch(InventoryActions.itemSearchCompleteRequest({
                search: $event.query,
                roomId: room.id,
              }))"
              (onSelect)="store.dispatch(InventoryActions.itemSearchSelected({
                item: $event.value,
                roomId: room.id,
                doFocus: 'item-search-' + room.id,
              }))"
              (onDropdownClick)="store.dispatch(InventoryActions.itemSearchSelected({
                item: $event.value,
                roomId: room.id,
                doFocus: 'item-search-' + room.id,
              }))"
              (keyup.enter)="store.dispatch(InventoryActions.itemSearchEnterPressed({
                key: $event,
                roomId: room.id,
                doFocus: 'item-search-' + room.id,
              }))"
              optionLabel="reviewerName"
            />
          </td>
          <td class="hidden-sm"></td>
          <!-- Quantity -->
          <td class="hidden-sm"></td>
          <td class="hidden-sm"></td>
          <td class="show-sm"></td>
          <!-- <td></td> -->
          <td class="inventory-actions">
            <div>
              <p-button
                *ngIf="itemSearch.value?.length"
                icon="pi pi-save"
                [rounded]="true"
                [text]="true"
                tooltipPosition="left"
                pTooltip="Add {{ search$ | async }} to {{
                  room.name
                }} as custom item"
                (click)="store.dispatch(InventoryActions.itemSearchSave({
                    roomId: room.id,
                    doFocus: 'item-search-' + room.id,
                }));"
              />
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

</section>

<div
  *ngIf="(rooms$ | async).length === 0"
  class="noRooms"
>
  This inventory is empty. Add a room to get started.
</div>

<ng-template #loading>
  <p-skeleton
    class="flex-1"
    borderRadius="2.1875rem"
    *ngFor="let _ of [1, 2, 3, 4]"
    width="100%"
    height="4rem"
  />
</ng-template>

<p-dialog
  header="Add Room"
  [modal]="true"
  [visible]="addRoomDialogVisible$ | async"
  [style]="{ width: '25rem', maxHeight: '80vh' }"
  (visibleChange)="store.dispatch(InventoryActions.addRoomDialogHidden())"
>
  <div class="room-options">
    <p-button
      class="room-option"
      [autofocus]="room.focus"
      [outlined]="room.exists"
      [severity]="room.exists ? 'secondary' : 'primary'"
      *ngFor="let room of addRoomOptions$ | async"
      (click)="store.dispatch(InventoryActions.addRoomRoomSelected({
        room: room.name,
      }))"
    >
      Add {{ room.name }}
    </p-button>
  </div>
  <div class="room-custom-option">
    <p-floatLabel>
      <input
        id="addRoomCustom"
        type="text"
        pInputText
        #addRoomCustom
        (keydown.enter)="
          store.dispatch(
            InventoryActions.addRoomRoomSelected({ room: addRoomCustom.value })
          )
        "
      />
      <label for="addRoomCustom">Add Other Room...</label>
    </p-floatLabel>
    <p-button
      icon="pi pi-plus"
      placeholder="Other..."
      severity="secondary"
      [outlined]="true"
      [disabled]="!addRoomCustom.value"
      (click)="store.dispatch(InventoryActions.addRoomRoomSelected({
        room: addRoomCustom.value,
      }))"
    ></p-button>
  </div>
  <!-- <div class="flex justify-content-end gap-2"> -->
  <!-- <p-button label="Cancel" severity="secondary" (onClick)="visible = false" /> -->
  <!-- <p-button label="Save" (onClick)="visible = false" /> -->
  <!-- </div> -->
</p-dialog>

<p-dialog
  header="Edit Item"
  class="edit-item-dialog"
  [modal]="true"
  [visible]="editItemDialogVisible$ | async"
  [style]="{ width: '25rem', maxHeight: '80vh' }"
  (visibleChange)="
    store.dispatch(
      InventoryActions.editItemDialogToggleVisible({ visible: false })
    )
  "
>
  <div
    *ngIf="editItem$ | async as editItem"
    class="dialog-content"
  >
    <div class="p-fluid form-grid">
      <!-- Name -->
      <div class="p-field p-col-12">
        <p-floatLabel>
          <input
            pInputText
            id="item-name"
            [ngModel]="editItem.name"
            (ngModelChange)="
              store.dispatch(
                InventoryActions.editItemUpdated({ key: 'name', value: $event })
              )
            "
          />
          <label for="item-name">Name</label>
        </p-floatLabel>
      </div>

      <!-- Quantity -->
      <div class="p-field p-col-12">
        <p-floatLabel>
          <p-inputNumber
            id="item-quantity"
            [ngModel]="editItem.quantity"
            [min]="0"
            [step]="1"
            [showButtons]="true"
            (ngModelChange)="
              store.dispatch(
                InventoryActions.editItemUpdated({
                  key: 'quantity',
                  value: $event
                })
              )
            "
          ></p-inputNumber>
          <label for="item-quantity">Quantity</label>
        </p-floatLabel>
      </div>

      <!-- Weight -->
      <div class="p-field p-col-12">
        <p-floatLabel>
          <p-inputNumber
            id="item-weight"
            [ngModel]="editItem.weight"
            [step]="0.01"
            mode="decimal"
            (ngModelChange)="
              store.dispatch(
                InventoryActions.editItemUpdated({
                  key: 'weight',
                  value: $event
                })
              )
            "
          ></p-inputNumber>
          <label for="item-weight">Weight ({{ weightUnit$ | async }})</label>
        </p-floatLabel>
      </div>

      <!-- Volume -->
      <div class="p-field p-col-12">
        <p-floatLabel>
          <p-inputNumber
            id="item-volume"
            [ngModel]="editItem.volume"
            [step]="0.01"
            mode="decimal"
            (ngModelChange)="
              store.dispatch(
                InventoryActions.editItemUpdated({
                  key: 'volume',
                  value: $event
                })
              )
            "
          ></p-inputNumber>
          <label for="item-volume">Volume ({{ volumeUnit$ | async }})</label>
        </p-floatLabel>
      </div>
    </div>
  </div>

  <div class="flex justify-content-end gap-2 dialog-footer">
    <p-button
      label="Cancel"
      severity="secondary"
      class="p-button-outlined"
      (onClick)="
        store.dispatch(
          InventoryActions.editItemDialogToggleVisible({ visible: false })
        )
      "
    />
    <p-button
      label="Save"
      class="p-button-success"
      (onClick)="store.dispatch(InventoryActions.editItemSaved())"
    />
  </div>
</p-dialog>


<ng-template #bookSmartConsultSplitButton>
  <p-splitButton
    [disabled]="(isCreatingSelfSurvey$ | async) === true || (isBookingYemboSmartConsult$ | async) === true"
    class="full-width-tiered-menu"
    icon="pi pi-book"
    label="Book Yembo Smart Consult"
    (onClick)="bookSmartConsult()"
    [model]="splitMenuItems"
  />
</ng-template>

<ng-template #bookSmartConsultButton>
  <p-button
    [loading]="(isBookingYemboSmartConsult$ | async) === true"
    icon="pi pi-book"
    label="Book Yembo Smart Consult"
    (onClick)="bookSmartConsult()"
  />
</ng-template>