import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';
import { BaseFieldFragment, Charge, CreateCalendarEventInput, CreateChargeInput, CreateDiscountInput, Discount, Expense, FullJobFragment, Job, JobsV2PageQuery, Tax } from 'graphql.generated';
import { EventWithCharges } from 'src/app/estimates/estimate-confirmation/estimate-confirmation.component';

import { FullJobFragmentWithFields } from '../../job-tool.reducer';
import { CalendarEventWithLockedAndInvoicedFlag, ChargesUpdate, SummaryUpdate, UnsavedChangesToastInfo } from '../../jobv2-create/jobv2-interfaces';

type DocumentType = 'estimate' | 'all-documents' | 'invoice';

export const WorkOrdersActions = createActionGroup({
   source: 'Work Orders',
   events: {
      'Fill Out Events With Charges': props<{ calculatedEvents: EventWithCharges[] }>(),

      'Taxes Loaded': props<{ taxes: Tax[] }>(),
      'Expenses Loaded': props<{ expenses: Expense[] }>(),

      'Available Discounts Loading': emptyProps(),
      'Available Discounts Loaded Success': props<{ discounts: Partial<Discount>[] }>(),
      'Available Discounts Loaded Error': props<{ error: Error }>(),

      'Product For Adding Selected': props<{
         productId: string,
         priceId: string,
         quantity: number,
         eventId: string,
      }>(),
      'Products For Adding Submitted': emptyProps(),
      'Product For Adding Cancelled': emptyProps(),

      'Existing Charges Updated': props<{
         chargeId: string,
         quantity?: number,
         amount?: number,
         eventId?: string,
      }>(),

      'Save Button Clicked': emptyProps(),
      'Cancel button Clicked': props<{ eventId?: string }>(),

      'Changes Saved No Changes': props<{
         originalAction: Action,
      }>(),
      'Changes Saved Success': props<{
         job: FullJobFragment,
         fields: Partial<BaseFieldFragment>[],
         originalAction: Action,
      }>(),
      'Changes Saved Error': props<{ error: Error }>(),

      'Add Discount': props<{
         discounts: Array<{
           discountId: string;
           eventId: string;
           customAmount?: number;
         }>;
       }>(),

      'Remove Discount': props<{
         appliedId: string,
      }>(),

      'Create Single Use Discount': props<{
         createDiscountInput: CreateDiscountInput,
         eventsIds: string[]
      }>(),

      'Create Custom Charge': props<{
         createChargeInput: CreateChargeInput,
         userId: string //job customer id
      }>(),

      'Apply Unsaved Changes From LS': props<{
         unsavedChanges: ChargesUpdate[],
         unsavedSummaryChanges: SummaryUpdate[],
      }>(),
      'Generate Unsaved Changes Toasts': props<{
         unsavedChangesToastsInfo: UnsavedChangesToastInfo[],
      }>(),
      'Update Charges Order': props<{
         targetEvent: Partial<CalendarEventWithLockedAndInvoicedFlag>,
         insertedCharge: Charge,
         insertedChargeIndex: number,
         isInsertedChargeNew?: boolean,
         donorEvent?: Partial<CalendarEventWithLockedAndInvoicedFlag>,
      }>(),

      'Create Event': props<{ eventInput: CreateCalendarEventInput }>(),

      'Remove Charge': props<{
         chargeId: string,
         eventId: string,
      }>(),

      'Duplicate Event': props<{ eventInput: Partial<CalendarEventWithLockedAndInvoicedFlag> }>(),

      'Cancel Event': props<{ eventId: string }>(),

      'Delete Event': props<{ eventId: string }>(),

      'Reorder Events': props<{ eventsWithNewOrder: { eventId: string, newOrder: number }[] }>(),

      'Work Order Unsaved Changes Dumped to LS': emptyProps(),

      'Add Document Button Clicked': props<{ createDocument: DocumentType }>(),
   },
});
