<p-breadcrumb
  [home]="home"
  [model]="breadcrumb"
  styleClass="p-mb-3"
  >
</p-breadcrumb>
<div class="card">
  <div class="header-with-button">
    <div class="p-d-flex p-ai-center">
      <h3>{{ existingReport ? existingReport.name : 'New Report'}}</h3>
      <p-message
        severity="warn"
        text="This report has unsaved changes"
        styleClass="p-ml-2"
        *ngIf="hasChanges">
      </p-message>
    </div>
    <p-button
      *ngIf="!existingReport"
      label="Save"
      tooltipPosition="left"
      class="p-mr-2"
      icon="pi pi-save"
      (onClick)="openCreateDynamicReportDialog()"
      [disabled]="saving"
      [outlined]="true"
      ></p-button>
      <p-splitButton
        *ngIf="existingReport"
        label="Save"
        [pTooltip]="hasChanges ? '' : 'No changes to save'"
        tooltipPosition="left"
        class="p-mr-2"
        icon="pi pi-save"
        (onClick)="updateReport()"
        [disabled]="saving || (existingReport && !hasChanges)"
        [outlined]="true"
        [model]="existingReportActions"
        appendTo="body"
        ></p-splitButton>
  </div>
  <p *ngIf="existingReport">{{ existingReport.description }}</p>
  <p-messages
    *ngIf="freyaHelper.inRootOrCorporateZone"
    severity="warn"
    styleClass="p-mb-2"
    >
    <ng-template pTemplate>
      <div>
        <b>You are running this report in a corporate zone.</b>
        <br>
        <span>This may impact performance application-wide. Please be mindful not to run this during operation hours, and to select a reasonable reporting period.</span>
      </div>
    </ng-template>
  </p-messages>

  <div class="p-col" [formGroup]="reportParamsForm" *ngIf="reportParamsForm">
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <label>Filter By
          <app-info-circle
            class="info-circle"
            pTooltip="May affect totals, e.g., a job may have been created this month, while the event was scheduled for next month (or not scheduled yet)."
            ></app-info-circle>
        </label>
        <br>
        <p-dropdown
          [options]="periodTypeOptions"
          formControlName="periodType"
          optionLabel="label"
          optionValue="option"
          styleClass="full-width"
          appendTo="body"
          ></p-dropdown>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Period</label>
        <br>
        <p-dropdown
          [options]="periods"
          formControlName="period"
          optionLabel="name"
          optionValue="value"
          filter="true"
          styleClass="full-width"
          appendTo="body"
          ></p-dropdown>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Dates</label>
        <br>
        <p-calendar
          appendTo="body"
          formControlName="customPeriod"
          (onClose)="resetCustomPeriod()"
          selectionMode="range"
          [readonlyInput]="true"
          styleClass="full-width"
          inputId="range"
          ></p-calendar>
      </div>
      <div class="p-col-12 p-d-flex p-ai-center">
        <p-chip
          *ngFor="let chip of chips"
          [label]="chip.name + ': ' + (chip.value | filterValue)"
          class="p-mr-2"
          [removable]="true"
          (onRemove)="resetFilter(chip.ctrlName)"
          ></p-chip>
        <app-add-filter
          *ngIf="filters"
          #addFilter
          [filters]="filters"
          ></app-add-filter>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <h3>Summary</h3>
  <div class="p-col" [formGroup]="reportParamsForm" *ngIf="reportParamsForm">
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <label>Columns</label>
        <br>
        <p-multiSelect
          class="full-width-multiselect"
          [options]="aggregationColumnOptions"
          formControlName="aggregationColumns"
          optionlabel="label"
          optionValue="option"
          ></p-multiSelect>
      </div>
    </div>
  </div>
  <p-table
    [value]="aggregationRows"
    [loading]="reportQH.loading"
    breakpoint="620px"
    [reorderableColumns]="true"
    (onColReorder)="handleColReorder($event, 'aggregationColumns')"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let header of aggregationHeaders" pReorderableColumn>
          {{ header }}
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-row
      >
      <tr>
        <td *ngFor="let header of aggregationHeaders; index as i">
          <span>{{ row[i] }}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card">
  <div class="header-with-button">
    <h3>Data</h3>
    <div class="p-d-flex">
      <div class="p-d-flex p-flex-column p-mr-2">
        <p-button
          label="Refresh"
          styleClass="full-width"
          icon="pi pi-refresh"
          (onClick)="previewReport()"
          [disabled]="!refreshEnabled || actionsLoading || reportQH.loading"
          [outlined]="true"
          ></p-button>
        <div class="p-mt-1" *ngIf="lastRefresh">
          Refreshed <app-time-since [unixTime]="lastRefresh"></app-time-since>
        </div>
        <div class="p-mt-1" *ngIf="!lastRefresh">
          Loading...
        </div>
      </div>
      <p-splitButton
        [label]="actionsLoading ? 'Processing...' : 'Copy Data'"
        [icon]="actionsLoading ? 'pi pi-spin pi-spinner' : 'pi pi-copy'"
        (onClick)="copyToClipboard()"
        [model]="dataActions"
        [disabled]="actionsLoading || reportQH.loading"
        appendTo="body"
        ></p-splitButton>
    </div>
  </div>
  <p-message
    severity="warn"
    text="This report may contain duplicate revenue, i.e. some charges may be added to multiple rows."
    styleClass="p-ml-2"
    *ngIf="groupByWithDuplicateRevenue[groupBy]">
  </p-message>
  <div class="p-col" [formGroup]="reportParamsForm" *ngIf="reportParamsForm">
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <label>Group By</label>
        <br>
        <p-dropdown
          [options]="groupByOpts"
          formControlName="groupBy"
          optionlabel="label"
          optionValue="option"
          styleClass="full-width"
          ></p-dropdown>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Columns</label>
        <br>
        <p-multiSelect
          class="full-width-multiselect"
          [options]="dataColumnOptions"
          formControlName="dataColumns"
          optionlabel="label"
          optionValue="option"
          ></p-multiSelect>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Sort By</label>
        <br>
        <p-dropdown
          [options]="sortByOptions"
          formControlName="sort"
          optionLabel="label"
          optionValue="option"
          styleClass="full-width"
          appendTo="body"
          ></p-dropdown>
      </div>
    </div>
  </div>
  <p-table
    *ngIf="reportParamsForm"
    [value]="dataRows"
    [paginator]="true"
    (onLazyLoad)="retrieveMoreRows($event)"
    [lazy]="true"
    [totalRecords]="reportQH.total"
    [(rows)]="reportQH.limit"
    [(first)]="reportQH.skip"
    [rowsPerPageOptions]="pagination.rowPerPageOptions"
    [loading]="reportQH.loading"
    [showCurrentPageReport]="!reportQH.loading"
    [reorderableColumns]="true"
    (onColReorder)="handleColReorder($event, 'dataColumns')"
    breakpoint="620px"
    >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let header of dataHeaders" pReorderableColumn>
          {{ header }}
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-row
      >
      <tr>
        <td *ngFor="let header of dataHeaders; index as i">
          <app-report-data
            [content]="row[i]"
            [column]="groupBy | titlecase"
            [row]="row"
            ></app-report-data>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card">
  <div class="header-with-button"> 
    <h3>Schedule</h3>
    <p-button
      label="Schedule"
      [pTooltip]="!existingReport ? 'You must save your report before scheduling' : ''"
      tooltipPosition="left"
      class="p-mr-2"
      icon="pi pi-calendar-plus"
      (onClick)="openCreateScheduleReportDialog()"
      [disabled]="reportQH.loading || !existingReport"
      [outlined]="true"
      ></p-button>
  </div>
  <app-scheduled-reports-list
    *ngIf="existingReport; else emptySchedule"
    [load]="true"
    [dynamicReportId]="existingReport.id"
    >
  </app-scheduled-reports-list>

</div>

<ng-template #emptySchedule>
  <p-table #table>
      <ng-template pTemplate="header">
          <tr>
              <th>Name</th>
              <th>Zone</th>
              <th>Last Run</th>
              <th>Next Run</th>
              <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body">
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td
            [attr.colspan]="'6'"
            class="empty txt"
            >
            Nothing scheduled
          </td>
        </tr>
      </ng-template>
  </p-table>
</ng-template>

<div [formGroup]="reportParamsForm" *ngIf="reportParamsForm">
  <ng-template appTemplateType="jobStage">
    <div class="p-mb-1">
      <label>Job Stage</label>
    </div>
    <p-listbox
      [options]="jobStages"
      [multiple]="true"
      [checkbox]="true"
      formControlName="jobStage"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="jobState">
    <div class="p-mb-1">
      <label>Job State</label>
    </div>
    <p-listbox
      [options]="jobStates"
      [multiple]="true"
      [checkbox]="true"
      formControlName="jobState"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="currency">
    <div class="p-mb-1">
      <label>Currency</label>
    </div>
    <p-dropdown
      [options]="currencyOptions"
      formControlName="currency"
      styleClass="full-width"
      appendTo="body"
      ></p-dropdown>
  </ng-template>
  <ng-template appTemplateType="jobSources">
    <div class="p-mb-1">
      <label>Sources</label>
    </div>
    <p-chips
      formControlName="jobSources"
      class="full-width-chips"
      styleClass="full-width"
      class="full-width-chips"
      ></p-chips>
  </ng-template>
  <ng-template appTemplateType="jobOrigins">
    <div class="p-mb-1">
      <label>Sources</label>
    </div>
    <p-chips
      formControlName="jobOrigins"
      class="full-width-chips"
      styleClass="full-width"
      class="full-width-chips"
      ></p-chips>
  </ng-template>
  <ng-template appTemplateType="jobTypes">
    <div class="p-mb-1">
      <label>Types</label>
    </div>
    <p-chips
      formControlName="jobTypes"
      class="full-width-chips"
      styleClass="full-width"
      class="full-width-chips"
      ></p-chips>
  </ng-template>
  <ng-template appTemplateType="jobHasCompletedEventOfType">
    <div class="p-mb-1">
      <label>Has Completed Event of Type</label>
    </div>
    <p-listbox
      [options]="eventTypes"
      [multiple]="true"
      [checkbox]="true"
      formControlName="jobHasCompletedEventOfType"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="eventStatus">
    <div class="p-mb-1">
      <label>Event Status</label>
    </div>
    <p-listbox
      [options]="eventStatuses"
      [multiple]="true"
      [checkbox]="true"
      formControlName="eventStatuses"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="eventType">
    <div class="p-mb-1">
      <label>Event Type</label>
    </div>
    <p-listbox
      [options]="eventTypes"
      [multiple]="true"
      [checkbox]="true"
      formControlName="eventTypes"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="eventSharedStatus">
    <div class="p-mb-1">
      <label>Shared</label>
    </div>
    <p-listbox
      [options]="eventSharedStatuses"
      [multiple]="true"
      [checkbox]="true"
      formControlName="eventSharedStatuses"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="bookedOnDaysOfWeek">
    <div class="p-mb-1">
      <label>Booked On Day of the Week</label>
    </div>
    <p-listbox
      [options]="daysOfTheWeek"
      [multiple]="true"
      [checkbox]="true"
      formControlName="bookedOnDaysOfWeek"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="bookedFromHour">
    <div class="p-mb-1">
      <label>Booked No Earlier Than</label>
    </div>
    <p-calendar
      [timeOnly]="true"
      formControlName="bookedFromHour"
      ></p-calendar>
  </ng-template>
  <ng-template appTemplateType="bookedUntilHour">
    <div class="p-mb-1">
      <label>Booked No Later Than</label>
    </div>
    <p-calendar
      [timeOnly]="true"
      formControlName="bookedUntilHour"
      ></p-calendar>
  </ng-template>
  <ng-template appTemplateType="distanceMin">
    <div class="p-mb-1">
      <label>Distance Min</label>
    </div>
    <p-inputNumber
      placeholder="Distance Min (meters)"
      formControlName="distanceMin"
      ></p-inputNumber>
  </ng-template>
  <ng-template appTemplateType="distanceMax">
    <div class="p-mb-1">
      <label>Distance Max</label>
    </div>
    <p-inputNumber
      placeholder="Distance Max (meters)"
      formControlName="distanceMax"
      ></p-inputNumber>
  </ng-template>
  <ng-template appTemplateType="realizedBy">
    <div class="p-mb-1">
      <label>Realized By</label>
      <br>
      <small>When requesting "Realized revenue" or "unrealized revenue" columns, only count as 'realized' revenue for events that were completed by this date .</small>
    </div>
    <p-calendar
      appendTo="body"
      formControlName="realizedBy"
      styleClass="full-width"
      [showTime]="true"
      [hourFormat]="24"
      inputId="range"
      (onBlur)="refreshReport()"
      ></p-calendar>
  </ng-template>
  <ng-template appTemplateType="invoiceStatus">
    <div class="p-mb-1">
      <label>Invoice Status</label>
    </div>
    <p-listbox
      [options]="invoiceStatuses"
      [multiple]="true"
      [checkbox]="true"
      formControlName="invoiceStatuses"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="invoiceBalance">
    <div class="p-mb-1">
      <label>Invoice Status</label>
    </div>
    <p-listbox
      [options]="invoiceBalances"
      [multiple]="true"
      [checkbox]="true"
      formControlName="invoiceBalances"
      optionlabel="label"
      optionValue="option"
      ></p-listbox>
  </ng-template>
  <ng-template appTemplateType="excludeDamages">
    <div class="p-mb-1">
      <label>Exclude Damages</label>
    </div>
    <p-checkbox
        formControlName="excludeDamages"
        binary="true"
        label="Exclude Damages"
    ></p-checkbox>
  </ng-template>
</div>

<p-dialog
  header="Semi-Live Export to Google Sheets"
  [(visible)]="exportDialogOpen"
  [modal]="true"
  [breakpoints]="{'992px': '62vw', '510px': '93vw'}"
  [style]="{width: '50vw'}"
  >
	<span>
		Keep an up to date copy of {{ existingReport?.name }} in a Google Sheet. 
	</span>
	<br /><br />
	<ul>
		<li>
			Copy the formula below by clicking on it and paste it into a Google Sheet cell with
			CTRL + V.
		</li>
		<li>
      Google Sheets may ask you to allow access to third-party links. Click on "Allow access" to enable the formula to work.
		</li>
		<li>
			Google Sheets will run this report every hour.
		</li>
		<li>
			This formula will only be valid for a year then you will have to generate a new one.
		</li>
		<li>
			<strong>
				Keep this formula a secret! It gives
				anyone with this formula access to your report!
			</strong>
		</li>
	</ul>
  <div class="p-my-4">
    <div [formGroup]="exportSettingsForm" class="p-d-flex p-jc-evenly" style="gap: 0.5rem">
      <div class="p-d-flex p-ai-center" style="gap: 0.5rem">
        <p-checkbox
          formControlName="includeSummary"
          binary="true"
          inputId="includeSummary"
          ></p-checkbox>
        <label for="includeSummary">Include Summary</label>
      </div>
      <div class="p-d-flex p-ai-center" style="gap: 0.5rem">
        <p-checkbox
          formControlName="includeData"
          binary="true"
          inputId="includeData"
          ></p-checkbox>
        <label for="includeData">Include Data</label>
      </div>
    </div>
    <small class="field-error-message p-text-center p-mt-2" *ngIf="exportSettingsForm.hasError('noSelection')">
      Must include either Summary or Data
    </small>
  </div>
  <app-copy-button
    *ngIf="exportFormula"
    [content]="exportFormula"
    [disabled]="exportSettingsForm.hasError('noSelection') || exportTokenRefreshing"
    ></app-copy-button>
  <div class="p-mt-3 p-d-flex p-jc-center">
    <p-button
      (click)="copyFormulaAndCreateNewSheet()"
      [label]="exportTokenRefreshing ? 'Refreshing link' : 'Copy formula and create new sheet'"
      class="p-mt-2"
      icon="pi pi-file-excel"
      [disabled]="!exportFormula || exportSettingsForm.hasError('noSelection') || exportTokenRefreshing"
      ></p-button>
  </div>
</p-dialog>
