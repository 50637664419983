import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { DiscountsGQL, Expense, ListExpensesGQL, ListTaxesGQL, Tax, UpdateJobGQL } from "graphql.generated";
import { catchError, map, from, of, switchMap, tap, withLatestFrom, distinctUntilChanged, filter, exhaustMap } from "rxjs";

import { DocumentHelperService } from "src/app/services/document-helper.service";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";

import { brandingFeature } from "src/app/state/branding.store";
import { InventoryActions } from '../../job-inventory/inventory.actions';
import { JobToolActions } from "../../job-tool.actions";
import { jobToolFeature } from "../../job-tool.reducer";

import { generateListDiscountsQueryVariables, getJobUnsavedChanges } from "../../jobsv2-charges-helpers";
import { JobSummaryActions } from "../summary-state/summary.actions";

import { WorkOrdersActions } from "./workorders.actions";

//load discounts, expenses and taxes every time when state?.job?.id changed
export const triggerLoadAvailableDiscountsOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    map(() => WorkOrdersActions.availableDiscountsLoading())
  );
}, { functional: true, dispatch: true });

export const loadTaxesOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);
  const listTaxesGQL = inject(ListTaxesGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() =>
      listTaxesGQL.fetch({}).pipe(
        filter(res => !res.loading),
        map(res => {
          const taxes = res.data?.taxes?.taxes || [];
          return WorkOrdersActions.taxesLoaded({ taxes: taxes as Tax[] });
        })
      )
    )
  );
}, { functional: true, dispatch: true });

export const loadExpensesOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);
  const listExpensesGQL = inject(ListExpensesGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() =>
      listExpensesGQL.fetch({}).pipe(
        filter(res => !res.loading),
        map(res => {
          const expenses = res.data?.expenses?.expenses || [];
          return WorkOrdersActions.expensesLoaded({ expenses: expenses as Expense[] });
        })
      )
    )
  );
}, { functional: true, dispatch: true });


export const loadAvailableDiscountsEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const discountsGQL = inject(DiscountsGQL);
  const store = inject(Store);

  return actions$.pipe(
    ofType(WorkOrdersActions.availableDiscountsLoading),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
    ),
    filter(([_, job]) => !!job?.zone?.id),
    switchMap(([_, job]) => {

      const {
        listDiscountsQueryVariables,
        headers
      } = generateListDiscountsQueryVariables(job?.zone?.id);

      return from(discountsGQL.fetch(listDiscountsQueryVariables, headers)).pipe(
        map(response => {
          const discounts = response.data.discounts.discounts || [];

          return WorkOrdersActions.availableDiscountsLoadedSuccess({ discounts });
        }),
        catchError(error =>
          of(WorkOrdersActions.availableDiscountsLoadedError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });

export const saveChangesEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const updateJobGQL = inject(UpdateJobGQL);
  const store = inject(Store);
  const localNotify = inject(FreyaNotificationsService);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.saveButtonClicked,
      InventoryActions.saveButtonClicked,
      WorkOrdersActions.addDocumentButtonClicked,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectChargesUpdates),
      store.select(jobToolFeature.selectSummaryUpdates),
      store.select(brandingFeature.selectProducts),
      store.select(jobToolFeature.selectUser),
    ),
    // do not execute save until this has been fully saved
    exhaustMap(([action, job, chargesUpdates, summaryUpdates, products, user]) => {
      const updateJobQueryVariables = getJobUnsavedChanges(job, chargesUpdates, summaryUpdates, products, user);

      if (updateJobQueryVariables) {
        return updateJobGQL.mutate(updateJobQueryVariables).pipe(
          map((result) => {
            const job = result?.data?.updateJobs?.jobs[0];
            const fields = result?.data?.updateJobs?.fields;
            if (!job || !fields) {
              throw new Error(`Changes not returned from update`);
            }

            localNotify.addToast.next({ severity: 'success', summary: 'Job saved' });

            return WorkOrdersActions.changesSavedSuccess({
              job,
              fields,
              originalAction: action,
            });
          }),
          catchError((error) => {
            // Handle error and dispatch error action
            localNotify.error(`Cannot save job changes. Error occurred: ${error}`);
            return of(WorkOrdersActions.changesSavedError({ error }));
          })
        );
      } else {
        return of(WorkOrdersActions.changesSavedNoChanges({
          originalAction: action,
        }));
      }
    })
  );
}, { functional: true, dispatch: true });

export const CreateDocumentAfterChangesSavedEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const documentHelper = inject(DocumentHelperService);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.changesSavedSuccess,
      WorkOrdersActions.changesSavedNoChanges,
    ),
    filter((action) => action?.originalAction?.type === WorkOrdersActions.addDocumentButtonClicked.type),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectChargesUpdates),
      store.select(jobToolFeature.selectSummaryUpdates),
      store.select(brandingFeature.selectProducts),
      store.select(jobToolFeature.selectUser),
    ),
    tap(([action, job, chargesUpdates, summaryUpdates, products, user]) => {
      const originalAction = action.originalAction as ReturnType<typeof WorkOrdersActions.addDocumentButtonClicked>;

      switch (originalAction?.createDocument) {
        case 'estimate':
          documentHelper.openDocumentsDialog({
            jobId: job.id,
            jobCode: job.code,
            preselectTemplateKey: 'standard-documents.estimate',
            autogenerate: true,
          });
          break;
        case 'invoice':
          documentHelper.openCreateInvoiceDialog(job?.id);
          break;
        default:
        case 'all-documents':
          documentHelper.openDocumentsDialog({
            jobId: job.id,
            jobCode: job.code,
          });
          break;
      }
    }),
  );
}, { functional: true, dispatch: false });

// local storage
export const saveUnsavedChangesToLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  return actions$.pipe(
    ofType(
      WorkOrdersActions.productForAddingSelected,
      WorkOrdersActions.productsForAddingSubmitted,
      WorkOrdersActions.existingChargesUpdated,
      WorkOrdersActions.updateChargesOrder,
      WorkOrdersActions.reorderEvents,
      WorkOrdersActions.removeCharge,
      WorkOrdersActions.addDiscount,
      WorkOrdersActions.createSingleUseDiscount,
      WorkOrdersActions.createCustomCharge,
      WorkOrdersActions.removeDiscount,
      WorkOrdersActions.reorderEvents,
      WorkOrdersActions.cancelEvent,
      WorkOrdersActions.deleteEvent,
      WorkOrdersActions.createEvent,
      WorkOrdersActions.duplicateEvent,
      WorkOrdersActions.cancelButtonClicked,
      JobSummaryActions.updateSummary,
      JobToolActions.summaryInPlaceEditingCancelled,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectChargesUpdates),
      store.select(jobToolFeature.selectSummaryUpdates),
    ),
    tap(([_, job, chargesUpdates, summaryUpdates]) => {
      if (job) {
        const existingChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');

        const customer = job?.users?.find(u => u.role === 'customer').user;

        if(chargesUpdates.length === 0 && summaryUpdates.length === 0) {
          delete existingChanges[job.id];
          localStorage.setItem('workorders_unsaved_changes', JSON.stringify(existingChanges));
          return;
        }

        const updatedChanges = {
          ...existingChanges,
          [job.id]: {
            jobCode: job?.code,
            jobCustomer: (customer.givenName || '') + ' ' + (customer.familyName || ''),
            changes: chargesUpdates,
            summaryUpdates,
          }
        };

        localStorage.setItem('workorders_unsaved_changes', JSON.stringify(updatedChanges));
      }
    }),
    map(() => WorkOrdersActions.workOrderUnsavedChangesDumpedToLS())
  );
}, { functional: true, dispatch: true });

export const loadUnsavedWorkOrdersChangesFromLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);

  return actions$.pipe(
    ofType(JobToolActions.jobLoaded),
    withLatestFrom(store.select(jobToolFeature.selectJob)),
    map(([action, job]) => {
      const unsavedChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');
      const jobUnsavedChanges = unsavedChanges[job?.id] || { changes: [], summaryUpdates: [] };

      return WorkOrdersActions.applyUnsavedChangesFromLS({
        unsavedChanges: jobUnsavedChanges.changes,
        unsavedSummaryChanges: jobUnsavedChanges.summaryUpdates,
      });
    })
  );
}, { functional: true, dispatch: true });

export const removeSavedChangesFromLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);

  return actions$.pipe(
    ofType(WorkOrdersActions.changesSavedSuccess),
    withLatestFrom(store.select(jobToolFeature.selectJob)),
    tap(([_, job]) => {
      const unsavedChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');

      if (job?.id && unsavedChanges[job.id]) {
        delete unsavedChanges[job.id];
        localStorage.setItem('workorders_unsaved_changes', JSON.stringify(unsavedChanges));
      }
    }),
    map(() => WorkOrdersActions.workOrderUnsavedChangesDumpedToLS())
  );
}, { functional: true, dispatch: true });

//unsaved changes toasts
export const generateUnsavedChangesRemindersEffect = createEffect(() => {
  const actions$ = inject(Actions);

  return actions$.pipe(
    ofType(JobToolActions.jobLoaded),
    map(() => {
      const unsavedChanges = JSON.parse(localStorage.getItem('workorders_unsaved_changes') || '{}');

      const unsavedChangesToastsInfo = Object.keys(unsavedChanges).map(jobId => {
        const { jobCode, jobCustomer } = unsavedChanges[jobId];
        return { jobId, jobCode, jobCustomer };
      });

      return WorkOrdersActions.generateUnsavedChangesToasts({ unsavedChangesToastsInfo });
    })
  );
}, { functional: true, dispatch: true });
