import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { BaseCalendarEventFragment, CalendarEvent, Charge, Discount, DiscountDetails, EstimatesJobFragment, Job } from 'graphql.generated';
import { cloneDeep } from 'lodash';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, map, switchMap, take, tap } from 'rxjs';
import { EventWithCharges } from 'src/app/estimates/estimate-confirmation/estimate-confirmation.component';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { JobEventStatus, MAX_32_BIT_INT, eventTypeInfoMapV2 } from 'src/app/global.constants';
import { HistoryService } from 'src/app/history/history.service';
import { OnlineStatusService } from 'src/app/online-status.service';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { ResponsiveHelperService } from 'src/app/services/responsive-helper.service';
import { TimezoneHelperService } from 'src/app/services/timezone-helper.service';
import { ShareEventZoneComponent } from 'src/app/shared/share-event-zone/share-event-zone.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubSink } from 'subsink';

import { AddDiscountsModalV2Component } from '../add-discounts-modal-v2/add-discounts-modal-v2.component';
import { AddEventButtonV2Component } from '../add-event-button-v2/add-event-button-v2.component';
import { JobEstimateComponent } from '../job-estimate/job-estimate.component';
import { OverviewChipComponent } from '../job-overview/overview-header/overview-chip/overview-chip.component';
import { TimelineBookEventComponent } from '../job-overview/overview-timeline/timeline-book-event/timeline-book-event.component';
import { ScheduleEventsActions } from '../job-state/event-schedule-state/event-schedule.actions';
import { eventScheduleSelectors, selectEventsWithMissingLocations } from '../job-state/event-schedule-state/event-schedule.selectors';
import { WorkOrdersActions } from '../job-state/workorders-state/workorders.actions';
import {
  selectEditableAmounts,
  workOrdersSelectors,
} from '../job-state/workorders-state/workorders.selectors';
import { JobEvent, jobToolFeature } from '../job-tool.reducer';
import { EventReordered, deletableEventStatuses, getDisabledStatus, getEditableAmount } from '../jobsv2-charges-helpers';
import { CalendarEventWithLockedAndInvoicedFlag } from '../jobv2-create/jobv2-interfaces';

import { AddChargesComponent } from './add-charges/add-charges.component';
import { FinancialsTableV2Component } from './financials-table-v2/financials-table-v2.component';

@Component({
  selector: 'app-job-workorders',
  standalone: true,
  imports: [
    SharedModule,
    FreyaCommonModule,
    AddChargesComponent,
    AddDiscountsModalV2Component,
    AddEventButtonV2Component,
    FinancialsTableV2Component,
    JobEstimateComponent,
    TimelineBookEventComponent,
    OverviewChipComponent,
  ],
  templateUrl: './job-workorders.component.html',
  styleUrls: [
    './job-workorders.component.scss',
    '../job-financials/document-container-style.scss',
  ],
})
export class JobWorkordersComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private history: HistoryService,
    private freyaHelper: FreyaHelperService,
    private documentHelper: DocumentHelperService,
    public responsiveHelper: ResponsiveHelperService,
    private detailsHelper: DetailsHelperService,
    public onlineStatusService: OnlineStatusService,
    public timezoneHelper: TimezoneHelperService,
  ) {
    this.isOnline = false;
  }

  private subs = new SubSink();
  public getDisabledStatus = getDisabledStatus;
  public getEditableAmount = getEditableAmount;

  subtotalLimit = MAX_32_BIT_INT;
  
  // Variables to keep track of whether a new job is loading
  jobLoading: boolean;
  isOnline: boolean;

  //lock date variables
  formattedLockDate?: string;

  //job, events and charges variables
  job: EstimatesJobFragment;
  charges: Charge[];

  eventsWithCharges: EventWithCharges[] = [];
  //all job events
  existingEventIds: string[] = [];

  isBookDialogVisible = false;
  jobEvents: JobEvent[];
  timezone: string | undefined = undefined;
  jobInDifferentTimezoneWarning: string | undefined = undefined;
  // The events that can have products into them (i.e., not locked, not invoiced)
  validEventIds: string[] = [];
  deletablEventStatuses = deletableEventStatuses;
  EventReordered = EventReordered;

  /**
   * Charge amounts converted to dollars so they can be bound to the appropriate UI components.
   */
  editableAmounts: Record<string, number> = {};

  //Variables to keep track of collapsed elements on the page
  isInitialCollapseState = true;
  isAllEventsCollapsed = false;
  collapsedEventFinancialsIds: Set<string> = new Set<string>();
  collapsedEventsIds: Set<string> = new Set<string>();

  showMoreEstimates = false;

  // Drag and drop functionality
  dragDelay = 100;
  draggingOverBreakdown = false;

  //menus
  discountActions: MenuItem[] = [];
  chargeActions: MenuItem[] = [];
  eventActions: MenuItem[] = [];

  //accounting
  defaultContactAccountingButtonText = 'Contact Accounting';
  contactAccountingButtonText = this.defaultContactAccountingButtonText;

  //observables
  job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);
  jobLoading$ = this.store.select(jobToolFeature.isJobLoading);
  jobBeingUpdated$ = this.store.select(jobToolFeature.isJobBeingUpdated);
  jobLoadingOrUpdating$ = this.store.select(jobToolFeature.isJobLoadingOrUpdating);
  chargesUpdates$ = this.store.select(jobToolFeature.selectChargesUpdates);
  eventIds$ = this.store.select(workOrdersSelectors.selectEventIds);
  editableAmounts$: Observable<Record<string, number>>;
  //for booking
  jobEvents$ = this.store.select(jobToolFeature.selectJobEvents);
  timezone$ = this.store.select(eventScheduleSelectors.selectJobTimezone);

  selectBookButtonDisabled = createSelector(
    selectEventsWithMissingLocations,
    (missingLocations: Record<string, string[]>) => (
      event: CalendarEventWithLockedAndInvoicedFlag,
      job: EstimatesJobFragment) => {
      const isMissingLocation = missingLocations?.hasOwnProperty(event?.id);
      const disabledStatus = getDisabledStatus(event, job).disabledStatus;
      return isMissingLocation || disabledStatus;
    }
  );

  selectBookButtonDisabledTooltip = createSelector(
    selectEventsWithMissingLocations,
    (missingLocations: Record<string, string[]>) => (
      event: CalendarEventWithLockedAndInvoicedFlag,
      job: EstimatesJobFragment) => {
      const isMissingLocation = missingLocations?.hasOwnProperty(event?.id);
      const eventMissingLocations = missingLocations?.[event?.id];
      const disabledStatus = getDisabledStatus(event, job).disabledStatus;

      if (isMissingLocation) {
        return `Missing Locations: ${eventMissingLocations?.join(', ')}`;
      }  else if (disabledStatus) {
        return disabledStatus;
      } else return '';
    }
  );

  selectEventHasUnsavedChanges = createSelector(
    workOrdersSelectors.selectEventIdsWithUnsavedChanges,
    (eventsWithUnsavedChangesIds: string[]) => (event: CalendarEventWithLockedAndInvoicedFlag) => {
      return eventsWithUnsavedChangesIds.includes(event?.id);
    }
  );

  eventHasUnsavedChanges$ = (event: CalendarEventWithLockedAndInvoicedFlag) =>
    this.store.select(this.selectEventHasUnsavedChanges).pipe(
      map((hasUnsavedChanges) => hasUnsavedChanges(event))
  );

  isBookButtonDisabled$ = (event: CalendarEventWithLockedAndInvoicedFlag, job: EstimatesJobFragment) =>
    this.store.select(this.selectBookButtonDisabled).pipe(
      map((isDisabledFn) => isDisabledFn(event, job))
  );

  bookButtonDisabledTooltip$ = (event: CalendarEventWithLockedAndInvoicedFlag, job: EstimatesJobFragment) =>
    this.store.select(this.selectBookButtonDisabledTooltip).pipe(
      map((isDisabledTooltipFn) => isDisabledTooltipFn(event, job))
  );

  latestEstimate$ = this.store.select(jobToolFeature.selectLatestEstimate);
  olderEstimates$ = this.store.select(jobToolFeature.selectOlderEstimates);
  estimatesEmpty$ = this.store.select(createSelector(
    jobToolFeature.selectDocuments,
    (documents) => documents.filter((doc) => doc.attributes?.includes('Estimate')).length === 0,
  ));

  ngOnInit(): void {

    this.onlineStatusService.isOnline$.subscribe(status => {
      this.isOnline = status;
    });

    this.subs.sink = this.job$.subscribe((job) => {
      if (job) {

        this.job = job;

        this.eventsWithCharges = this.job?.events as any[];
      }
    });

    // TODO: Prevent piping selectors into each other. Instead, use the store.select() method to select the data you need.
    this.editableAmounts$ = this.job$.pipe(
      map((job) => job?.events || []),
      // TODO: (events as any) This is a temporary fix to avoid a runtime error. We should fix the type of events in the job state.
      map((events) => selectEditableAmounts(events as any)),
      switchMap((editableAmountsSelector) =>
        this.store.select(editableAmountsSelector).pipe(
          tap((editableAmounts) => {
            this.editableAmounts = editableAmounts;
          })
        )
      )
    );

    // Ensure editableAmounts$ observable executes and updates this.editableAmounts
    this.subs.sink = this.editableAmounts$.subscribe();

    this.subs.sink = this.jobLoading$.subscribe((jobLoading) => {
      this.jobLoading = cloneDeep(jobLoading);
    });

    this.subs.sink = this.eventIds$.subscribe((eventIds) => {
      this.validEventIds = cloneDeep(eventIds.validEventIds);
      this.existingEventIds = cloneDeep(eventIds.existingEventIds);
    });

    this.formattedLockDate = this.freyaHelper.getFormattedLockDate();

    this.freyaHelper.lockDateSupportInfo$.subscribe((info) => {
      this.contactAccountingButtonText = info?.contactAccountingButtonText || this.defaultContactAccountingButtonText;
    });

    this.subs.sink = this.jobEvents$.subscribe((events) => {
      this.jobEvents = events || [];
    });

    this.subs.sink = this.timezone$.subscribe((timezone) => {
      this.timezone = timezone;

      const systemTimeZone = this.timezoneHelper.getCurrentTimezone();
      if (timezone !== systemTimeZone) {
        this.timezone = timezone;
        this.jobInDifferentTimezoneWarning = `You are selecting event start time in ${timezone} timezone`
      } else {
        this.jobInDifferentTimezoneWarning = '';
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  trackByEventId(index: number, event: any): any {
    return event?.id ?? index;
  }

  //drag and drop
  drop(event: CdkDragDrop<CalendarEventWithLockedAndInvoicedFlag>) {
    const {
      container: { data: targetEvent },
      item: { data: charge, dropContainer: sourceContainer },
      currentIndex
    } = event;

    const donorEvent: EventWithCharges = sourceContainer.data;

    if (donorEvent?.id === targetEvent?.id) {
      // reordered inside same event
      this.store.dispatch(WorkOrdersActions.updateChargesOrder({
          targetEvent: donorEvent,
          insertedCharge: charge,
          insertedChargeIndex: currentIndex,
          isInsertedChargeNew: false
      }));

  } else {
    // moved from one event to other
      this.store.dispatch(WorkOrdersActions.updateChargesOrder({
        targetEvent: targetEvent,
        insertedCharge: charge,
        insertedChargeIndex: currentIndex,
        isInsertedChargeNew: true,
        donorEvent: donorEvent,
    }));
    }
  }

  handleDragEnter(event: CdkDragDrop<CalendarEventWithLockedAndInvoicedFlag>) {

  }

  //collapsing methods
  toggle(collection: Set<string>, id: string) {
    if (collection.has(id)) {
      collection.delete(id);
    } else {
      collection.add(id);
    }
  }

  toggleEvent(id: string) {
    this.toggle(this.collapsedEventsIds, id);
    if (this.collapsedEventsIds.size === this.eventsWithCharges.length) {
      this.isAllEventsCollapsed = true;
    } else {
      this.isAllEventsCollapsed = false;
    }
  }

  toggleAllEvents() {
    if (this.isAllEventsCollapsed) {
      this.collapsedEventsIds.clear();
      this.isAllEventsCollapsed = false;
    } else {
      this.eventsWithCharges.forEach((event: EventWithCharges) => {
        this.collapsedEventsIds.add(event?.id);
      });
      this.isAllEventsCollapsed = true;
    }
  }

  isEventCollapsed(id: string): boolean {
    return this.collapsedEventsIds.has(id);
  }

  isEventFinancialsCollapsed(id: string): boolean {
    return this.collapsedEventFinancialsIds.has(id);
  }

  //right side panel
  openCharge(charge?: Charge) {
    if (!charge) {
      return;
    }

    this.detailsHelper.open('charge', charge);
  }

  openDiscount(discount: Discount) {
    if (!discount) {
      return;
    }

    this.detailsHelper.open('discount', discount);
  }

  openEvent(id: string) {
    if (!id) {
      return;
    }

    this.detailsHelper.open('calendar-event', { id });
  }

  /**
   * If event has charges, show expanded Event Financials panel for it,
   * use isInitialCollapseState to track that it's done only once and avoid
   * losing collapsed state each time when we call setCharges
   */
  setInitialCollapseState(events: EventWithCharges[]) {
    if (this.isInitialCollapseState) {
      events?.forEach((event: EventWithCharges) => {
        if (event?.charges && event?.charges?.length > 0) {
          this.collapsedEventFinancialsIds.add(event.id);
        }
      });
      this.isInitialCollapseState = false;
    }
  }

  //discounts

  removeDiscount(discount: DiscountDetails) {
    this.store.dispatch(WorkOrdersActions.removeDiscount({ appliedId: discount.appliedId }));
  }

  openEditCustomAmountDialog(discount: DiscountDetails) {

  }

  //documents
  openDocuments() {
    this.documentHelper.openDocumentsDialog({
      jobId: this.job.id,
      jobCode: this.job.code,
      preselectTemplateKey: 'standard-documents.estimate',
      autogenerate: true,
    });
  }

  //accounting
  contactAccounting(event: CalendarEventWithLockedAndInvoicedFlag) {
    this.freyaHelper.contactAccounting(event.id);
  }

  //context menus

  getContextMenuDisabledStatus(event: CalendarEventWithLockedAndInvoicedFlag) {
    return this.jobLoading || event?.locked || event?.invoiced
  }

  setDiscountActions(discount: DiscountDetails) {
    const hasCustomRange = discount.discount.customAmountRange;

    this.discountActions = [
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.openEditCustomAmountDialog(discount),
        //disabled: !hasCustomRange,
        //TO DO - implement this logic
        disabled: true,
        tooltipOptions: {
          tooltipLabel: !hasCustomRange && 'Discount wasn\'t given a custom amount range',
          tooltipPosition: 'left',
        }
      },
      {
        label: 'Remove',
        icon: 'pi pi-trash',
        command: () => this.removeDiscount(discount),
      }
    ];

  }

  setChargeActions(charge: Charge, event: CalendarEvent) {
    this.chargeActions = [
      {
        label: 'Remove',
        icon: 'pi pi-trash',
        command: () => this.removeCharge(charge?.id, event?.id),
      }
    ];

  }

  setEventActions(event: CalendarEventWithLockedAndInvoicedFlag, index: number) {

    this.eventHasUnsavedChanges$(event)
      .pipe(take(1))
      .subscribe((eventHasUnsavedChanges) => {
      this.eventActions = [
        {
          label: 'Move To Top',
          icon: 'pi pi-arrow-circle-up',
          visible: index > 1,
          command: () => this.onMove(event, EventReordered.to_top),
        },
        {
          label: 'Move Up',
          icon: 'pi pi-arrow-up',
          visible: event.id !==
            this.eventsWithCharges[0]?.id,
          command: () => this.onMove(event, EventReordered.up),
        },
        {
          label: 'Move Down',
          icon: 'pi pi-arrow-down',
          visible: event.id !==
            this.eventsWithCharges[this.eventsWithCharges.length - 1]?.id,
          command: () => this.onMove(event, EventReordered.down),
        },
        {
          label: 'Move To Bottom',
          icon: 'pi pi-arrow-circle-down',
          visible: (this.eventsWithCharges.length >= 3
            && index < this.eventsWithCharges.length - 2),
          command: () => this.onMove(event, EventReordered.to_bottom),
        },
        {
          label: event?.status === 'booked' ? 'Reschedule' : 'Book',
          icon: 'pi pi-calendar-plus',
          command: () => this.bookButtonClicked(event),
          visible: !eventHasUnsavedChanges,
        },
        {
          label: 'Save',
          icon: 'pi pi-save',
          command: () => this.saveButtonClicked(),
          visible: eventHasUnsavedChanges,
        },
        {
          label: 'Duplicate',
          icon: 'pi pi-clone',
          command: () => this.duplicateEventAsRequired(event),
        },
        {
          id: 'cancel',
          label: 'Cancel',
          icon: 'pi pi-ban',
          command: () => this.confirmAndCancelEvent(event?.id),
          disabled: this.getContextMenuDisabledStatus(event),
          visible: !deletableEventStatuses.includes(event?.status as JobEventStatus),
        },
        {
          id: 'delete',
          label: 'Delete',
          icon: 'pi pi-trash',
          command: () => this.confirmAndDeleteEvent(event?.id),
          disabled: this.getContextMenuDisabledStatus(event),
          visible: deletableEventStatuses.includes(event?.status as JobEventStatus),
        },
        {
          label: 'View History',
          icon: 'pi pi-book',
          command: () => {
            if (!event?.id) { return; };
            this.history.openHistory('CalendarEvent', [ event?.id ]);
          },
        },
        //TO DO - rework ShareEventZoneComponent to make it compatible with global state
        //for now we use quick solution, as events are not shared often and even if it's shared
        //and state is not aware it doesn't affect following work in workorders
        {
          id: 'share',
          label: 'Share',
          icon: 'pi pi-arrow-up-right',
          command: () => {
            this.dialogService.open(ShareEventZoneComponent, {
              header: 'Share Event',
              data: {
                event: event,
                jobId: this.job?.id,
              },
            });
          },
        }
      ]
    })
  }

  duplicateEventAsRequired(event: CalendarEventWithLockedAndInvoicedFlag) {

    this.store.dispatch(WorkOrdersActions.duplicateEvent({ eventInput: event }))
  }

  confirmAndCancelEvent(eventId: string) {
    this.confirmationService.confirm({
      message: 'Cancelling this event will remove it from the schedule and remove any associated charges and discounts?',
      header: 'Cancel Event?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(WorkOrdersActions.cancelEvent({ eventId }));
      },
      reject: () => {}
    });
  }

  confirmAndDeleteEvent(eventId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this event?',
      header: 'Delete Calendar Event',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.store.dispatch(WorkOrdersActions.deleteEvent({ eventId }));
      },
      reject: () => {}
    });
  }

  onMove(event: CalendarEventWithLockedAndInvoicedFlag, direction: EventReordered) {

    const indexBeforeUpdate = this.eventsWithCharges.findIndex(item => item.id === event.id);

    if (direction === EventReordered.up) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, indexBeforeUpdate - 1);
    }

    if (direction === EventReordered.down) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, indexBeforeUpdate + 1);
    }

    if(direction === EventReordered.to_top) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, 0);
    }

    if(direction === EventReordered.to_bottom) {
      moveItemInArray(this.eventsWithCharges, indexBeforeUpdate, this.eventsWithCharges?.length - 1);
    }

    const eventsWithNewOrder = this.eventsWithCharges.map((event, index) => ({
      eventId: event.id,
      newOrder: index,
    }));

    this.store.dispatch(WorkOrdersActions.reorderEvents({ eventsWithNewOrder }))
  }

  //charges updates

  removeCharge(chargeId: string, eventId) {
    this.store.dispatch(WorkOrdersActions.removeCharge({
      chargeId,
      eventId
    }));
  }

  handleInputClick(event: any) {
    /*event.stopPropagation();
    if (typeof event?.target?.select === 'function') {
      event.target.select();
    }*/
  }


  handleQuantityChange(
    event: EventWithCharges,
    charge: Charge,
    quantity: number,
    field: 'quantity' | 'amount'
  ) {

    // For amount field don't allow null/undefined values and allow zero values
    // For quantity field don't allow null values as well as zero values.
    if (
      (field === 'amount' && quantity == null) ||
      (field === 'quantity' && !quantity)
    ) {
      return;
    }

    const payload = {
      eventId: event?.id,
      chargeId: charge?.id,
      ...(field === 'quantity' ? { quantity } : { amount: quantity })
    };
    this.store.dispatch(WorkOrdersActions.existingChargesUpdated(payload));
  }

  eventTypeInfoMap = eventTypeInfoMapV2;

  public openCreateEstimateDialog() {
    this.store.dispatch(WorkOrdersActions.addDocumentButtonClicked({ createDocument: 'estimate' }));
  }

  public closeBookEventDialog() {
    this.isBookDialogVisible = false;
  }

  bookButtonClicked(event: CalendarEventWithLockedAndInvoicedFlag) {
    this.store.dispatch(
      ScheduleEventsActions.openBookDialogButtonClicked({
          event,
      })
    );
  }

  saveButtonClicked() {
    this.store.dispatch(
      WorkOrdersActions.saveButtonClicked()
    );
  }
}
